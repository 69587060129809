// JavaScript Document


jQuery( document ).ready(function($) {
    
	$(document).foundation();
	
	
	// Customizing a reveal set
	window.sr = ScrollReveal();
	
	sr.reveal('.rvl', { 
		duration: 500,
		distance:'0px',
		scale:1,
		delay:100,
		reset:true
		});
	sr.reveal('.rvl-slow', { 
		duration: 2500,
		distance:'0px',
		scale:1,
		delay:30,
		reset:true
		});
	sr.reveal('.rvl-very-slow', { 
		duration: 500,
		distance:'0px',
		scale:1,
		delay:3000,
		reset:true,
		easing:'ease-in'
		}, 70);
	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1,
		reset:true
	}, 70);
	sr.reveal('.str-slow', { 
		duration: 500,
		origin: 'bottom',
		scale: 1,
		delay:2000,
		reset:true
	}, 70);
	$(window).scroll(function() {
		 if ($(this).scrollTop() > 250)
		 {
		  $('.sticky-header').addClass("active");
		 }
		 else
		 {
		  $('.sticky-header').removeClass("active");
		 }
	});
	$('#scroll-down').click(function(){
		$('html, body').animate({
		  scrollTop: $("#section-two").offset().top -100
		}, 1000);
	});
	$('#scroll-down-2').click(function(){
		$('html, body').animate({
		  scrollTop: $("#section-two-2").offset().top -100
		}, 1000);
	});
	//Footer SLIDER
	$('#footer-slider').slick({
		autoplay:true,
		fade:true,
		pauseOnHover:false,
		autoplaySpeed: 2000,
	    speed:1500,
	});
	
	//CONTACT FORM
	$('#contact_form')
	// to prevent form from submitting upon successful validation
	  .on("submit", function(ev) {
		ev.preventDefault();
		
		console.log("Submit for form id "+ev.target.id+" intercepted");
	 })	
	.on("forminvalid.zf.abide", function(ev,frm) {
		 console.log("Form id "+ev.target.id+" is invalid");
	  })
	.on("formvalid.zf.abide", function(ev,frm) {
		console.log("form is valid");
		$('#contact_form .button2').attr('disabled', 'true');
		$('#contact_form .loading').css('display','inline-block');
		var that = $('#contact_form'),
		url = that.attr("action"),
		method = that.attr("method"),
		data = {};
		that.find("[name]").each( function() {
			var that = $(this),
				name = that.attr("name"),
				value = that.val();
				data[name] = value;
		});
		$.ajax({
			url: url,
			type: method,
			data: data,
			success: function(response) {
				console.log('success');
				$('#contact_form').hide(); 
				$('.form .successMessage').show(); 

			},
			error: function(response){
				$('#contact_form .button1').attr('disabled', 'false');
				$('#contact_form .loading').hide();

			}

		});
	});
	
	
	
});
